<template lang="pug">
.templateManager
    v-row
        v-col.d-flex
            span.text-h5.brand--text Template Manager
            v-spacer
            v-btn(outlined, rounded, color='brand', @click='openNewTemplateDialog') Create Template
                v-icon(right) add
    v-row
        v-col.d-flex.flex-wrap
            EmailTemplateCard(v-for='template of templates', :key='template.id', :template='template')
                template(v-slot:cardActions)
                    v-btn.white--text.brand(small, rounded, @click='editTemplate(template)') Edit

    v-row(v-if='templates.length === 0')
        v-col
            span No templates have been added.
    v-dialog(v-model='newTemplateDialog', max-width='300')
        v-card(light)
            v-card-title New Template
            v-card-text
                v-row
                    v-col
                        v-text-field(label='Name', outlined, dense, color='brand', v-model='templateTemplate.name')
                v-row
                    v-col
                        v-textarea(label='Description', outlined, dense, color='brand', v-model='templateTemplate.description')
            v-card-actions
                v-spacer
                v-btn(@click='newTemplateDialog = false', text, :loading='saving') Cancel
                v-btn.white--text(color='brand', @click='addNewTemplate', :loading='saving') Create
</template>
<script>
import EmailTemplateCard from '@/components/devops/emailtemplatecard';
export default {
    meta: {
        role: 'devopscomms'
    },
    components: {
        EmailTemplateCard
    },
    data () {
        return {
            accessKey: 'devopscomms',
            newTemplateDialog: false,
            saving: false,
            templates: [],
            templateTemplate: {
                name: '',
                description: ''
            }
        };
    },
    methods: {
        async getTemplates () {
            const templates = await this.sendCommand({
                action: 'getEmailTemplates'
            });
            this.templates = templates;
        },
        async addNewTemplate () {
            this.saving = true;
            const newTemplate = await this.sendCommand({
                action: 'createEmailTemplate',
                parameters: this.templateTemplate
            });
            this.saving = false;
            this.editTemplate(newTemplate);
        },
        openNewTemplateDialog () {
            this.newTemplateDialog = true;
        },
        editTemplate (template) {
            this.$router.push({path: '/devops/editEmailTemplate', query: {id: template.id}});
        }
    },
    watch: {
        newTemplateDialog (visible) {
            if (!visible) {
                this.templateTemplate.name = '';
                this.templateTemplate.description = '';
            }
        }
    },
    mounted () {
        this.getTemplates();
    }
};
</script>
